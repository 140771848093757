@use "@amzn/awsui-design-tokens/polaris.scss" as awsui;
@import '@amzn/awsui-global-styles/polaris.css';
@import "@amzn/katal-components/styles.css";

html {
    overflow: hidden;
    height: 100%;
}

body {
    margin: 0px;
    overflow: auto;
    height: 100%;
}

.top-navigation {
    position: sticky;
    top: 0;
    z-index: 1002;
}

div[class^="awsui_drawer-content"] {
    top: 50px !important;
    display: flex !important;
    align-items: flex-start !important;
    height: 100% !important;
    position: static !important;
}

.fda-home-page {
    height: inherit;
    display: flex;
}

.fda-home-page .containers {
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    .container {
        height: 500px;
        width: 400px;
        margin: 20px;
    }
}

.fda-table {
    font-size: small !important;
    height: 100%;
}

.min-80-vh {
    min-height: 70vh;
}

.form-field-5rem {
    width: 5rem;
}

.form-field-select-width-20rem {
    width: 20rem;
}

.fda-split-panel {
    div[class^="awsui_content-side"] {
        padding-bottom: 100px !important;
    }
}
